import React from "react";
import {
    NavLink
} from "react-router-dom";

const CartMenu = () => {
    return <div className="profile-menu">
        <NavLink to="/cart" activeClassName="active" >Mi compra</NavLink>
        <NavLink to="/cart/address" activeClassName="active" >Dirección de envío</NavLink>
        <NavLink to="/cart/payment" activeClassName="active" >Método de pago</NavLink>
    </div>
};

export {CartMenu}
export default CartMenu;


  