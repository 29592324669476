import React from "react";

import {CartInfo} from './CartInfo';
import {CartAddress} from './CartAddress';
import {CartPayment} from './CartPayment';
import {CartSuccess} from './CartSuccess';
import {CartError} from './CartSuccess';


import {CartMenu} from '../components/CartMenu';

import {
    Switch,
    Route,
  } from "react-router-dom";
  
const Cart = () => {
    return <div className="profile">
        <CartMenu />
        <div className="profile-content">
            <Switch>
                <Route exact path="/cart">
                    <CartInfo />
                </Route>
                <Route exact path="/cart/address">
                    <CartAddress />
                </Route>
                <Route exact path="/cart/payment">
                    <CartPayment />
                </Route>
                <Route exact path="/cart/success">
                    <CartSuccess />
                </Route>
                <Route exact path="/cart/error">
                    <CartError />
                </Route>

            </Switch>
        </div>
    </div>
};

export {Cart};
export default Cart;