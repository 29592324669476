import React from "react";

import launch_video from '../assets/videos/Zaneo_Pantallas_ExperienciaInteractiva_30Nov20_V1_720p.mp4';

const Launch = () => {
    return <div className="launch-video" style={{width:"100%", display:"flex", flex:1, alignItems:"center", justifyContent:"center"}}>
        <video width="80%" controls>
            <source src={launch_video} type="video/mp4" />
        </video>
    </div>;
};

export {Launch};
export default Launch;