import React from "react";
import {
    NavLink
} from "react-router-dom";
import cart from "../assets/images/cart.png";

import {CartContext} from "../CartContext";

const Cart = () => {
    return <CartContext.Consumer>
        {({cart: userCart}) => {
            console.log(userCart);
            let products = 0;
            userCart.products?.forEach((product) => {
                products += product.amount;
            });
            return<div className="cart">
                     <NavLink to="/cart"><img src={cart} alt="Logo Vivacy"/>
        {products > 0 && <div className="cart-count">{products > 99 ? "+99" : products}</div>}</NavLink>
                </div>
        }
    }
    </CartContext.Consumer>
};

export {Cart}
export default Cart;


  