import React from "react";

const ProfileInfo = () => {
    return <div style={{width:'100%', height:'100%', display:"flex", flexFlow:"column", flex:"1", justifyContent:"center", alignItems:"center"}}>
        <form className="profile-info">
            <h3>Mi información</h3>
            <div className="profile-info-data">
                <div className="profile-info-value">
                    <span>Nombre</span>
                    <input type="text" />
                </div>
                <div className="profile-info-value">
                    <span>Apellidos</span>
                    <input type="text" />
                </div>
            </div>
            <div className="profile-info-data">
            <div className="profile-info-value">
                    <span>Correo electrónico</span>
                    <input type="text" />
                </div>
            </div>
            <div className="profile-info-data">
                <div className="profile-info-value">
                    <span>Status de membresía: Gold</span>
                </div>
            </div>
            <span>Nota: Para mantener el estatus de tu membresía recuerda mantener X pedidos al mes.</span>
            <div className="saveButton">
                <button className="actionButton">Guardar</button>
            </div>
        </form>
    </div>
};

export {ProfileInfo};
export default ProfileInfo;