import React from "react";

import {ProfileInfo} from './ProfileInfo';
import {ProfileOrders} from './ProfileOrders';

import {ProfileMenu} from '../components/ProfileMenu';

import {
    Switch,
    Route,
  } from "react-router-dom";
  
const Profile = () => {
    return <div className="profile">
        <ProfileMenu />
        <div className="profile-content">
            <Switch>
                <Route exact path="/profile/info">
                    <ProfileInfo />
                </Route>
                <Route exact path="/profile/orders">
                    <ProfileOrders />
                </Route>
                <Route exact path="/profile/password">
                    <ProfileInfo />
                </Route>
            </Switch>
        </div>
    </div>
};

export {Profile};
export default Profile;