import React, {useEffect, useState} from "react";
import {getOrder} from '../api/products';
import {CartContext} from '../CartContext';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import {savePaypal, saveContact} from '../api/products';
import {
    NavLink, useHistory
} from "react-router-dom";


const CartPayment = () => {
    
    const [order, setOrder] = useState(false);
    const [loading, setLoading] = useState(true);
    const [processing, setProcessing] = useState(false);
    const [success, setSuccess] = useState(false);
    const history = useHistory();
    useEffect(()=>{
        const p = async()=>{
            const order = await getOrder();
            setOrder(order);
            setLoading(false);
        }
        p();
    }, []);

    const processPaypal= async(details, order)=>{
        try{
            const result = await savePaypal(details, order);
            if(result){
                window.location.href='/cart/success?OrderID=' + order.orderID;
            }else{
                window.location.href= '/cart/save-error?OrderID=' + order.orderID;
            }
        }catch(e){
            window.location.href = '/cart/save-error?OrderID=' + order.orderID;
        }
    };

    const errorPaypal = () => {
        alert("Ocurrió un error procesando su pago con paypal. Verifique su información o elija otro método de pago.")
    }

    const payWithContact = async () => {
        setProcessing(true);
        try{
            const result = await saveContact();
            if(result){
                window.location.href='/cart/success?OrderID=' + order._id;
            }else{
                alert("Ocurrió un error guardando su orden. Por favor intente de nuevo más tarde")
            }
        }catch(e){
            alert("Ocurrió un error guardando su orden. Por favor intente de nuevo más tarde")
        }
        setProcessing(false);
    }

    if(loading){
        return <div style={{width:'100%', height:'100%', display:"flex", flexFlow:"column", flex:"1", justifyContent:"center", alignItems:"center"}}>
            <h3>Método de pago</h3>
        </div>
    }else if(order?.products?.length > 0){
        let total = 0;
        order.products.forEach((product)=>{
            let discountAmount = product.discount;
            if(!Number(discountAmount)) discountAmount = 0;
            total += product.priceList * product.amount - discountAmount;
        });
        total = total * 1.16;
        total = total.toFixed(2);
        return <div style={{display:'flex', flexDirection:'column', flex:1, width:'100%', alignItems:'center', justifyContent:'center', paddingLeft:'2rem', paddingRight:'2rem'}} className="paypal-container">
            
            <button disabled={processing} className={"contactar-button " + (processing? "actionButtonDisabled": "") }  onClick={payWithContact}>
                    Contactar con un representante
            </button>
            
            <PayPalScriptProvider options={{
                    currency:"MXN",
                    "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID || 'sb'
                }}>
                <PayPalButtons
                    createOrder={(data, actions)=>{
                        return actions.order.create({
                            purchase_units:[{
                                amount: {
                                    value: total
                                }
                            }],
                            application_context:{
                                shipping_preference:"NO_SHIPPING",
                                locale:'es-MX',
                            }
                        })
                    }}
                    onApprove={processPaypal}
                    style={{
                        color:'blue',
                        layout:'vertical',
                        shape: 'pill',
                        label:'pay',    
                    }}
                />
            </PayPalScriptProvider>
        </div>
    }else{
        return <h3>Aún no tienes productos en tu carrito.</h3>;
    }
};

export {CartPayment};
export default CartPayment;