import React, {useState} from "react";

import background from '../assets/images/home_background.jpg';
import copy from '../assets/images/home_copy.png';

import logo_colombia from '../assets/images/logo_zaneo_col.png';
import flag_colombia from '../assets/images/flag_colombia.png';
import logo_mexico from '../assets/images/logo_zaneo_mex.png';
import flag_mexico from '../assets/images/flag_mexico.png';
import logo_inmode from '../assets/images/logo_inmode.png';
import logo_quantificare from '../assets/images/logo_quantificare.png';
import logo_fotona from '../assets/images/logo_fotona.png';
import logo_ebiody from '../assets/images/logo_ebiody.png';
import logo_lpg from '../assets/images/logo_lpg.png';
import logo_vivacy from '../assets/images/logo_vivacy.png';
import logo_asirox from '../assets/images/logo_asirox.png';
import arrow from '../assets/images/arrow.png';

const rectHeight=35;
const sqHeight=45;

const _logo_vivacy = {
    src: logo_vivacy,
    height: rectHeight
}; 

const _logo_lpg = {
    src: logo_lpg,
    height: sqHeight
}

const _logo_fotona = {
    src: logo_fotona,
    height: rectHeight
}

const _logo_quantificare = {
    src: logo_quantificare,
    height: rectHeight
};

const _logo_asirox = {
    src: logo_asirox,
    height: rectHeight
};

const _logo_ebiody = {
    src: logo_ebiody,
    height: rectHeight
}

const _logo_inmode = {
    src: logo_inmode,
    height: sqHeight
};

const Home = () => {

    const [country, setCountry] = useState('mex');

    const logos = {
        'mex': [
            _logo_vivacy,
            _logo_lpg,
            _logo_fotona,
            _logo_quantificare,
            _logo_asirox,
            _logo_ebiody
        ],
        'col':[
            _logo_inmode,
            _logo_fotona,
            _logo_lpg,
            _logo_quantificare,
            _logo_ebiody
        ]
    };

    const zaneo = {
        'mex': logo_mexico,
        'col': logo_colombia
    }

    return <div style={{display:'flex', flexDirection:'column', width:'100%'}}>
        <div style={{position:'relative', display:"flex", flexFlow:"column", flex:"1", justifyContent:"center", alignItems:"center"}}>
            <img src={background} alt="Vivacy background" style={{objectFit:"cover", width:'100%', height:'100%', position:"absolute"}}/>
            <div style={{display:"flex", flexDirection:'column', width:"30%", position:"absolute", justifyContent:"space-around", alignItems:"center", right:"10%"}}>
                <img style={{width:"100%" }}src={copy} alt="Viva Vivacy copy"/>
                <button style={{marginTop:"2em"}} className="actionButton" onClick={()=>{window.location.href="/launch"}}>Ir a Lanzamiento</button>
            </div>
        </div>
        <div style={{boxSizing:'border-box', padding: "1rem", display:"flex", flexDirection:'column', width:'100%', backgroundColor:'#c6c6c6', alignItems:'center'}}>
            <div style={{display:"flex", flexDirection:"row", alignItems:"center", fontWeight:'bold'}}>
                <span style={{fontSize: '1rem'}}> UNA MARCA DISTRIBUIDA EN EXCLUSIVA POR: </span><img style={{marginLeft:'1.5rem', height:'40px'}} src={zaneo[country]} alt="Logo zaneo"/> 
                <div class="dropdown">
                    <div class="dropbtn">
                        <img src={country === 'mex' ? flag_mexico : flag_colombia} style={{maxWidth:'25px'}} alt="Bandera"/>
                        <img src={arrow} style={{maxWidth:'15px', marginLeft:'15px'}} alt="DropDown arrow" />
                    </div>
                    <div class="dropdown-content">
                        <a href="#" onClick={()=>{
                            setCountry(country==='mex'?'col':'mex');
                        }}>
                            <img src={country === 'mex' ? flag_colombia : flag_mexico} style={{maxWidth:'25px'}} alt="Bandera" />
                        </a>
                    </div>
                </div>


            </div>
            <div style={{display:"flex", flexWrap: 'wrap', flexDirection: "row", alignItems: 'flex-end', justifyContent:"space-around", width: "80%", marginTop: '1rem'}}>
                {
                    logos[country].map((logo, idx)=> <img style={{margin: '1rem 1rem', height: logo.height }} key={`logos_${country}_${idx}`} src={logo.src} alt="logos zaneo"/>)
                }
            </div>
        </div>        
    </div>
};

export {Home};
export default Home;