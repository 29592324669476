import React from "react";
import {
    NavLink
} from "react-router-dom";

const ProfileMenu = () => {
    return <div className="profile-menu">
        <NavLink to="/profile/info" activeClassName="active" >Mi información</NavLink>
        <NavLink to="/profile/orders" activeClassName="active" >Mis pedidos</NavLink>
        {false && <NavLink to="/profile/password" activeClassName="active" >Cambiar contraseña</NavLink>}
    </div>
};

export {ProfileMenu}
export default ProfileMenu;


  