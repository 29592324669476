import React, {useEffect, useState} from "react";
import {getOrder, updateProduct, deleteProduct} from '../api/products';
import {CartContext} from '../CartContext';
import {Modal} from '../components/Modal';
import trash_icon from '../assets/images/trash_icon.png';
import {
    NavLink
} from "react-router-dom";

import product_images from '../components/ProductImages';

const currencyFormatter = new Intl.NumberFormat('en-MX', {
    minimumFractionDigits:2
});

const Product = ({_id, name, priceList, caption, discount, color, children, amount}) => {
    let discountAmount = discount;
    if(!Number(discountAmount)){
        discountAmount = 0;
    }
    return <div key={`product_${_id}`} className="shopping-product" >
        <div className="shopping-product-image" style={{color:color, backgroundColor:color}}>
            <div className="shopping-product-caption"><img style={{width:'50%'}} src={product_images[caption]} /></div>
        </div>
        <div className="shopping-product-details" >
            <span className="shopping-product-name">{name}</span>
            <span>${currencyFormatter.format(priceList)} MXN</span>
            {children}
        </div>
        <div className="shopping-product-details" style={{textAlign:'right', alignItems:'flex-end'}}>
            <span>Sub total: ${currencyFormatter.format(priceList * amount) } MXN</span>
            <span>Descuentos: ${currencyFormatter.format(discountAmount)} MXN</span>
            <span>Total: ${currencyFormatter.format(priceList * amount - discountAmount) } MXN</span>
        </div>
    </div>
};

const CartProduct = ({product, setOrder}) => {
    const [amount, setAmount] = useState(product.amount);
    const [loading, setLoading] = useState(false);
    const updateAmount = (e)=>{
        const newValue = e.target.value;
        if(!Number(newValue) && newValue.length > 0) return;
        setAmount(newValue);
    }

    useEffect(()=>{
        console.log("Amount changed for product " + product._id + " to " + amount);
    }, [amount, product._id]);


    return <Product {...product}>
        <CartContext.Consumer>
            {
                ({updateCart}) => {
                    const updateCartProduct = async () => {
                        if(amount.length === 0 || !Number(amount) || amount < 0){
                            alert("Debe indicar el número de piezas");
                            return;
                        }
                        setLoading(true);
                        try{
                            const isSuccess = await updateProduct(product.id, amount);
                            if(isSuccess){
                                alert("Carrito actualizado");
                                updateCart(isSuccess);
                                setOrder(isSuccess);
                            }else{
                                alert("Ocurrió un error actualizando el carrito. Por favor intente de nuevo más tarde");
                            }
                        }catch(e){
                            alert("Ocurrió un error actualizando el carrito. Por favor intente de nuevo más tarde");
                        }
                        setLoading(false);
                    };
                    const deleteCartProduct = async () => {
                        if(amount.length === 0 || !Number(amount) || amount < 0){
                            alert("Debe indicar el número de piezas");
                            return;
                        }
                        setLoading(true);
                        try{
                            const isSuccess = await deleteProduct(product.id, amount);
                            if(isSuccess){
                                alert("Carrito actualizado");
                                updateCart(isSuccess);
                                setOrder(isSuccess);
                            }else{
                                alert("Ocurrió un error actualizando el carrito. Por favor intente de nuevo más tarde");
                            }
                        }catch(e){
                            alert("Ocurrió un error actualizando el carrito. Por favor intente de nuevo más tarde");
                        }
                        setLoading(false);
                    };

                    return <div className="product-amount-select" style={{display:"flex", flexDirection:'column', alignItems:'center', justifyContent:'center'}}>
                        <div><input style={{width:'2rem', height:'1rem', marginBottom:'1rem'}} onChange={updateAmount} value={amount}/> pz.</div>
                        <button
                            onClick={updateCartProduct}
                            disabled={loading} 
                            className={"actionButtonSmall " + (loading ? "actionButtonDisabled" : "") } 
                            style={{alignItems:'center', justifyContent:'space-between', display:'flex', marginBottom:'1rem'}}>
                            Actualizar
                        </button>
                        <button
                            onClick={deleteCartProduct}
                            disabled={loading} 
                            className={"actionButtonSmall " + (loading ? "actionButtonDisabled" : "") } 
                            style={{alignItems:'center', justifyContent:'space-between', display:'flex', backgroundColor:"transparent"}}>
                            <img src={trash_icon} 
                                alt="Eliminar producto"
                            />
                        </button>
                    </div>
                }    
            }
        </CartContext.Consumer>
    </Product>
}


const CartInfo = () => {
    
    const [order, setOrder] = useState(false);
    const [loading, setLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [percent, setPercent] = useState(0.0);
    useEffect(()=>{
        const p = async()=>{
            const order = await getOrder();
            setOrder(order);
            setLoading(false);
        }
        p();
    }, []);
    
    const checkAverage = (evt)=>{
        let totalProds = 0;
        order.products.forEach((product)=>{
            totalProds += product.amount;
        });
        if(order.avg > 0){
            let percent = (100 - (totalProds * 100 / order.avg));
            if ( percent >= 10 ){
                setShowModal(true);
                setPercent(percent);
                evt.preventDefault();
            }
        }
        return true;
    };
    if(loading){
        return <div style={{width:'100%', height:'100%', display:"flex", flexFlow:"column", flex:"1", justifyContent:"center", alignItems:"center"}}>
            <h3>Mi compra</h3>
        </div>
    }else if(order?.products?.length > 0){
        let total = 0;
        let discounts = 0;
        order.products.forEach((product)=>{
            total += product.priceList * product.amount;
            discounts += (product.discount || 0);
        });
        return <div style={{width:'100%', height:'100%', display:"flex", flexFlow:"column", flex:"1", justifyContent:"center", alignItems:"center"}}>
            <div className="cart-products-list">
                {
                    order.products.map((product) => {
                        return <CartProduct setOrder={setOrder} product={product}/>
                    })
                }
            </div>
            <div style={{backgroundColor:'#00969c', width:'100%', height:'2px'}}></div>
            <div className="cart-totals" style={{width:'100%', paddingLeft:'2rem', paddingRight:'2rem', boxSizing:'border-box', fontWeight:'bold', marginBottom:'1.5rem'}}>
                <div className="shopping-product-details" style={{textAlign:'right', alignItems:'flex-end'}}>
                <span>Sub total productos: {currencyFormatter.format(total) } MXN</span>
                    <span>Descuentos: {currencyFormatter.format(discounts)} MXN</span>
                    <span>Sub total: {currencyFormatter.format(total - discounts) } MXN</span>
                    <span>IVA: {currencyFormatter.format((total-discounts) * 0.16)} MXN</span>
                    <span>Total: {currencyFormatter.format((total - discounts) * 1.16) } MXN</span>
                </div>
            </div>
            <div className="cart-buttons" style={{flexDirection:'row-reverse', display:'flex', alignItems:'flex-end', width:'100%', paddingLeft:'2rem', paddingRight:'2rem', boxSizing:'border-box', fontWeight:'bold', textDecoration:'none'}}>
                <NavLink to="/cart/address" className="actionButton" style={{textDecoration:'none', marginLeft:'1rem'}} onClick={checkAverage} >Continuar</NavLink>
                <NavLink to="/shopping" className="actionButton" style={{textDecoration:'none', backgroundColor:'#00969c'}}>Seguir comprando</NavLink>
            </div>
            <Modal
                show={showModal}
                cancel={()=>{setShowModal(false);}}
                ok={()=>{window.location.href='/cart/address';}}
            >
                <div>
                    <p>¿Está seguro que desea ordenar <span style={{fontWeight:'bold', color:'#00969c'}}>{Math.trunc(percent)}%</span> menos que su compra habitual?</p>
                </div>
            </Modal>
        </div>;
    }else{
        return <div>
            <h3>Aún no tienes productos en tu carrito. </h3>
            <NavLink to="/shopping" className="actionButton" style={{textDecoration:'none', backgroundColor:'#00969c'}}>Ir a Tienda</NavLink>
        </div>;
    }
};

export {CartInfo};
export default CartInfo;