import React from "react";

import videos_ergonomia from '../assets/videos/ergonomia_1080p.mp4';
import videos_formulacion from '../assets/videos/formulacion_1080p.mp4';
import videos_laboratorios from '../assets/videos/laboratorios_1080p.mp4';
import videos_sala from '../assets/videos/sala_de_espera_1080p.mp4';
import videos_trazabilidad from '../assets/videos/trazabilidad_1080p.mp4';

import social_ergonomia from '../assets/videos/ergonomia_720p.mp4';
import social_formulacion from '../assets/videos/formulacion_720p.mp4';
import social_laboratorios from '../assets/videos/laboratorios_720p.mp4';
import social_sala from '../assets/videos/sala_de_espera_720p.mp4';
import social_trazabilidad from '../assets/videos/trazabilidad_720p.mp4';
import social_1 from '../assets/social/Post_JeringaenAgua.jpg';
import social_2 from '../assets/social/Post_MerryChristmas.jpg';
import social_3 from '../assets/social/Post_StylageGama.jpg';
import social_4 from '../assets/social/Post_STYLAGEhand.jpg';
import social_5 from '../assets/social/Post_STYLAGEilustration.jpg';
import social_6 from '../assets/social/Post_STYLAGElarge.jpg';
import social_7 from '../assets/social/Post_STYLAGEmedium.jpg';
import social_8 from '../assets/social/Post_STYLAGEmediumDoc.jpg';
import social_9 from '../assets/social/Post_STYLAGEXChristmas.jpeg';
import social_10 from '../assets/social/Post_STYLAGEXChristmasIlustration.mp4';
import social_11 from '../assets/social/Pst_STYLAGElips.jpg';


import poster_hombre from '../assets/impresos/poster_hombre.pdf';
import poster_mujer from '../assets/impresos/poster_mujer.pdf';

const data = {
    'library-section-videos':[{
        name: 'Video Ergonomia 1080p',
        url: videos_ergonomia
    },{
        name: 'Video Formulación 1080p',
        url: videos_formulacion
    },{
        name: 'Video Laboratorios 1080p',
        url: videos_laboratorios
    },{
        name: 'Video Sala de espera 1080p',
        url: videos_sala
    }, {
        name: 'Video Trazabilidad 1080p',
        url: videos_trazabilidad
    }],
    'library-section-social':[{
        name: 'Video Ergonomia 720p',
        url: social_ergonomia
    },{
        name: 'Video Formulación 720p',
        url: social_formulacion
    },{
        name: 'Video Laboratorios 720p',
        url: social_laboratorios
    },{
        name: 'Video Sala de espera 720p',
        url: social_sala
    }, {
        name: 'Video Trazabilidad 720p',
        url: social_trazabilidad
    }, {
        name: 'Post Jeringa en Agua',
        url: social_1,
    }, {
        name: 'Post Merry Christmas',
        url: social_2,
    }, {
        name: 'Post Stylage Gama',
        url: social_3,
    }, {
        name: 'Post Stylage Hand',
        url: social_4,
    }, {
        name: 'Post Stylage Ilustration',
        url: social_5,
    }, {
        name: 'Post Stylage Large',
        url: social_6,
    }, {
        name: 'Post Stylage Medium',
        url: social_7,
    }, {
        name: 'Post Stylage Medium Doc',
        url: social_8,
    }, {
        name: 'Post Stylage X Christmas',
        url: social_9,
    }, {
        name: 'Post Stylage X Christmas Ilustration',
        url: social_10,
    }, {
        name: 'Post Stylage Lips',
        url: social_11,
    }],
    'library-section-impreso':[{
        name: 'Poster Stylage Indicaciones Mujer',
        url: poster_mujer
    },{
        name: 'Poster Stylage Indicaciones Hombre',
        url: poster_hombre
    }],
    'library-section-learning':[{
        name: 'Vivacy Learn',
        url: 'https://vivacy-learn.com/en/',
        text: 'Visitar'
    }]
};

const LibraryData = ({section}) => {
    console.log(section);
    const sectionData = data[section];

    return <div className={"library-data-downloads"}>
        {sectionData.map((data) => (
            <div className="library-download">
                <span>{data.name}</span>
                <a href={data.url} target="_blank" download={data.name} rel="noopener noreferer" className="actionButton">{data.text ? data.text : 'Descargar'}</a> 
            </div>
        ))}
    </div>
};

export {LibraryData}
export default LibraryData;


  