import React, {useEffect, useState} from "react";
import {loadProducts, addToCart} from "../api/products";
import cart from "../assets/images/cart_white.png";

import {CartContext} from "../CartContext";
import product_images from '../components/ProductImages';

const currencyFormatter = new Intl.NumberFormat('en-MX', {
    minimumFractionDigits:2
});
  
const Product = ({_id, name, priceList, caption, color, children}) => {
    return <div key={`product_${_id}`} className="shopping-product" >
        <div className="shopping-product-image" style={{color:color, backgroundColor:color}}>
            <div className="shopping-product-caption"><img style={{width:'50%'}} src={product_images[caption]} /></div>
        </div>
        <div className="shopping-product-details" >
            <span className="shopping-product-name">{name}</span>
            <span>${currencyFormatter.format(priceList)} MXN</span>
        </div>
        <div className="shopping-product-actions">
            {children}
        </div>
    </div>
};

const ShoppingProduct = ({product}) => {
    const [amount, setAmount] = useState(0);
    const [loading, setLoading] = useState(false);
    const updateAmount = (e)=>{
        const newValue = e.target.value;
        if(!Number(newValue) && newValue.length > 0) return;
        setAmount(newValue);
    }

    useEffect(()=>{
        console.log("Amount changed for product " + product._id + " to " + amount);
    }, [amount, product._id]);


    return <Product {...product}>
        <div className="product-amount-select">
            <input style={{width:'2rem', height:'1rem', marginBottom:'1rem'}} onChange={updateAmount} value={amount}/> pz.
        </div>
        <CartContext.Consumer>
            {
                ({updateCart}) => {
                    const addCartProduct = async () => {
                        if(amount.length === 0 || !Number(amount) || amount < 0){
                            alert("Debe indicar el número de piezas");
                            return;
                        }
                        setLoading(true);
                        try{
                            const isSuccess = await addToCart(product._id, amount);
                            if(isSuccess){
                                alert("Producto agregado al carrito");
                                updateCart(isSuccess);
                            }else{
                                alert("Ocurrió un error agregando el producto al carrito. Por favor intente de nuevo más tarde");
                            }
                        }catch(e){
                            alert("Ocurrió un error agregando el producto al carrito. Por favor intente de nuevo más tarde");
                        }
                        setLoading(false);
                    };
                    return <button
                        onClick={addCartProduct}
                        disabled={loading} 
                        className={"actionButtonSmall " + (loading ? "actionButtonDisabled" : "") } 
                        style={{alignItems:'center', justifyContent:'space-between', display:'flex'}}>
                        <img 
                            src={cart} 
                            style={{width:'1em', height:'1em', marginRight:'1em'}} 
                            alt="Shopping cart" />
                        Agregar al carrito
                    </button>
    
                }    
            }
        </CartContext.Consumer>
    </Product>

}

const Shop = ({products})=>{

    return products.map((product)  => {
        return <ShoppingProduct product={product} />
    })
}
const Spinner = ()=>{
    return <div>Loading</div>;
}
const Shopping = () => {
    const [load, setLoading] = useState(true);
    const [products, setProducts] = useState([]);

    useEffect(()=>{
        const p = async() => {
            setLoading(false);
            const prods = await loadProducts();
            console.log(prods);
            setProducts(prods);
        };
        p();
    }, []);

    if(load){
        return <div>
            <Spinner />
        </div>        
    }else{
        return <div className="shopping-products-list">
            <Shop products={products} />
        </div>
    }
};

export {Shopping};
export default Shopping;