import React,  {useState, useEffect} from "react";
import {loginUser} from '../api/auth';
import img_jeringas from '../assets/images/jeringas.png';
import img_logo from '../assets/images/vivacy_logo.png';

const Login = () => {

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [loginSucces, setLoginSuccess] = useState(false);
    const [processing, setProcessing] = useState(false);
    var emailPattern = new RegExp(/^\S+@\S+$/i);

    const processLogin = async() => {
        setError('');
        setProcessing(true);
        try {
            if(!name || !email || !password){
                setError('Todos los campos son requeridos');
                setProcessing(false);
                return;
            }

            if(!emailPattern.test(email)){
                setError('Inserte un correo electrónico válido');
                setProcessing(false);
                return ;
            }
            const user = await loginUser({
                name, email, password
            });
            if(user){
                setLoginSuccess(true);
            }else{
                setError(['Credenciales Incorrectas.', <br/>, 'Puede comunicarse al número: 56 2026 9103 para recibir asistencia']);
            }
        } catch(e){
            console.log(e);
            setError('Error procesando. Intente de nuevo más tarde');
        }
        setProcessing(false);
    }

    useEffect(()=>{
        if(loginSucces){
            window.location.href = '/home';
        }
    }, [loginSucces])

    return <div className="launch-content">"
            <section className="login-header mobile">
                        <img src={img_logo} alt="logo vivacy prime" />
            </section>
            <section className="login-jeringas">
                <img src={img_jeringas} alt="logo jeringas" />
            </section>
            <section className="login-wrapper">
                <section className="login-hello">
                    <span>Bienvenido</span>
                </section>
                <section className="login-info">
                    Para iniciar sesión por favor ingrese su nombre completo, email y contraseña:
                </section>
                <form className="login-form">
                    <input placeholder="Nombre completo" type="text" onChange={(e)=>{setName(e.target.value)}} />
                    <input placeholder="Email" autoCapitalize="off" autoCorrect="off" type="text" onChange={(e)=>{setEmail(e.target.value)}} />
                    <input placeholder="Contraseña" type="password" onChange={(e)=>{setPassword(e.target.value)}} />
                </form>
                <button disabled={processing} className="actionButton" onClick={processLogin}>Ingresar</button>
                    {error && <span className="login-error">{error}</span>}
                {false && <a href="/" className="forgot">Olvidé mi contraseña</a>}
            </section>
        </div>        
};

export {Login};