import React from "react";
import img_box from '../assets/images/trace_box.png';
import img_box_logo from '../assets/images/trace_box_logo.png';
import img_product from '../assets/images/trace_product.png';
import img_product_logo from '../assets/images/trace_product_logo.png';
import img_app from '../assets/images/trace_app.png';
import img_app_logo from '../assets/images/trace_app_logo.png';

const Trace = () => {
    return <div className="trace-content">
        <section className="trace-header">
            <div><span>PRODUCTOS </span><span>SEGUROS,</span></div>
            <div><span>CLIENTES </span><span>SATISFECHOS.</span></div>
        </section>
        <section className="trace-info">
            La calidad y seguridad siempre han sido parte del ADN de Vivacy. <br/>
            Vivacy controla cada paso del proceso de producción para no comprometer en ningún momento la seguridad. <br/>
            La misión global del laboratorio es innovar para crear nuevos productos y experiencias a los médicos especializados en estética.
        </section>
        <section className="trace-details">
            <article className="trace-details-article">
                <div className="trace-details-logos">
                    <img src={img_box} alt=""/>
                    <img src={img_box_logo} alt=""/>
                </div>
                <div className="trace-details-text">
                    <span className="trace-details-article-title">ÁCIDO HIALURÓNICO PATENTADO</span>
                    <span className="trace-info">Los productos STYLAGE® están basados en la exclusiva tecnología patentada IPN-Like para definir con precisión las propiedades viscoelásticas de cada producto. Se añaden antioxidantes a los geles de AH para proteger la formulación del ataque de los radicales libres durante la inyección.</span>
                </div>
            </article>
            <article className="trace-details-article">
                <div className="trace-details-logos">
                    <img src={img_product} alt="" />
                    <img src={img_product_logo} alt="" />
                </div>
                <div className="trace-details-text">
                    <span className="trace-details-article-title">NUEVA GENERACIÓN DE JERINGAS</span>
                    <span className="trace-info">VIVACY ha desarrollado Bi-SOFT® Injection Technology para proporcionar mayor precisión, confort y control a los médicos al realizar las inyecciones con los rellenos dérmicos STYLAGE ®.</span>
                </div>
            </article>
            <article className="trace-details-article">
                <div className="trace-details-logos">
                    <img src={img_app} alt="" />
                    <img src={img_app_logo} alt="" />
                </div>
                <div className="trace-details-text">
                    <span className="trace-details-article-title">SOLO PRODUCTOS AUTÉNTICOS</span>
                    <span className="trace-info">Las etiquetas de los productos cuentan con chips RFID (Radio Frecuency Identification) para verificar fácilmente el origen de los inyectables STYLAGE®, utilizando un smartphone con la aplicación dedicada "My Vivacy". </span>
                    <a href="https://itunes.apple.com/ro/app/my-vivacy-international/id1206429694?mt=8" target="_blank" rel="noopener noreferrer"><img class="alignnone wp-image-271" src="https://vivacylab.com/wp-content/uploads/appstore.png" alt="" width="140" height="53" srcset="https://vivacylab.com/wp-content/uploads/appstore.png 360w, https://vivacylab.com/wp-content/uploads/appstore-88x33.png 88w" /></a>
                    <a href="https://play.google.com/store/apps/details?id=eu.vivacy.vivacysecurity" target="_blank" rel="noopener noreferrer"><img class="alignnone wp-image-272" src="https://vivacylab.com/wp-content/uploads/googleplay.png" alt="" width="138" height="49" srcset="https://vivacylab.com/wp-content/uploads/googleplay.png 394w, https://vivacylab.com/wp-content/uploads/googleplay-88x31.png 88w" /></a>
                </div>
            </article>
        </section>
    </div>
};

export {Trace};
export default Trace;