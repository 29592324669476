import React from "react";

const CartSuccess = () => {
    return <div style={{width:'80%', margin:'1rem auto', display:'flex', alignItems:'center', justifyContent:'center'}}>
        <h3>Su orden ha sido recibida de manera exitósamente. En breve un representante se contactará con usted para agendar los detalles de envío.<br/>Si requiere facturar su pedido favor de enviar sus datos de facturación a los correos: <a href="mailto:arnaud.zanotti@zaneo.net">arnaud.zanotti@zaneo.net</a>  y  <a href="mailto:gabriela.ponce@zaneo.net">gabriela.ponce@zaneo.net</a></h3>
        
    </div>
};

const CartError = () => {
    const orderId= new URLSearchParams(window.location.search).get("OrderID")
    return <div style={{width:'80%', margin:'1rem auto', display:'flex', alignItems:'center', justifyContent:'center'}}>
        <h3>Ocurrió un error procesando su petición. Para continuar con su pedido, favor de comunicarse con un representante y notificar el número de orden: <span style={{fontWeight:'bold', color:"#00969c"}}>{`${orderId}`}</span></h3>
    </div>
}

export {CartSuccess, CartError};
export default CartSuccess;