import React, {useState, useEffect} from "react";
import {UnAuthMenu, UnAuthMobileMenu} from "../components/Menu";

import {UnAuthHeader} from "../components/Header";

import {Burger} from "../components/Burger";

import {useLocation} from 'react-router-dom';
import ReactGA from 'react-ga';

function UnauthenticatedLayout({children}){
    const location = useLocation();
    useEffect(()=>{
      ReactGA.pageview(location.pathname + location.search);
    },[location]);
    const [open, setOpen] = useState(false);
    return <div className="main">
        <div className="desktop-header">
            <UnAuthHeader />
            <UnAuthMenu />
        </div>
        <div className="mobile-header">
            <div className="mobile-burguer">
                <Burger open={open} setOpen={setOpen} />
                <UnAuthHeader />
            </div>
        </div>
        <div className="content">
            <UnAuthMobileMenu open={open} setOpen={setOpen}/>
            <div style={{display:'flex', flex:1}} onMouseDown={()=>{setOpen(false);}}>
                {children}
            </div>
       </div>
    </div>
};

export {UnauthenticatedLayout};
export default UnauthenticatedLayout;