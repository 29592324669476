import React, { useEffect, useState } from "react";
import image_stylage_s from '../assets/images/stylage_s.png';
import image_stylage_m from '../assets/images/stylage_m.png';
import image_stylage_l from '../assets/images/stylage_l.png';
import image_stylage_lips from '../assets/images/stylage_lips.png';

import {getOrders} from '../api/products';
import product_images from '../components/ProductImages';

const currencyFormatter = new Intl.NumberFormat('en-MX', {
    minimumFractionDigits:2
});

const Product = ({_id, name, priceList, caption, discount, color, children, amount}) => {
    let discountAmount = discount;
    if(!Number(discountAmount)){
        discountAmount = 0;
    }
    return <div key={`product_${_id}`} className="shopping-product" >
        <div className="shopping-product-image" style={{color:color, backgroundColor:color}}>
            <div className="shopping-product-caption"><img style={{width:'40%'}} src={product_images[caption]} /></div>
        </div>
        <div className="shopping-product-details" >
            <span className="shopping-product-name">{name}</span>
            <span>${currencyFormatter.format(priceList)} MXN</span>
            {children}
        </div>
        <div className="shopping-product-details" style={{textAlign:'right', alignItems:'flex-end'}}>
            <span>Sub total: ${currencyFormatter.format(priceList * amount) } MXN</span>
            <span>Descuentos: ${currencyFormatter.format(discountAmount)} MXN</span>
            <span>Total: ${currencyFormatter.format(priceList * amount - discountAmount) } MXN</span>
        </div>
    </div>
};

const CartProduct = ({product}) => {
    return <Product {...product}>
        <div className="product-amount-select" style={{display:"flex", flexDirection:'column', alignItems:'center', justifyContent:'center'}}>
            <div> {product.amount} pz.</div>
        </div>
    </Product>
}

const Order = ({order}) => {
    const [open, setOpen] = useState(false);
    
    const toggleOpen = ()=>{
        setOpen(!open);
    }

    console.log(order);
    let total = 0;
    let discounts = 0;
    if(order.products){
        order.products.forEach((product)=>{
            let discountAmount = product.discount;
            if(!Number(discountAmount)) discountAmount = 0;
            total += product.amount * product.priceList;
            discounts += discountAmount;
        });
    }
    total = total.toFixed(2);
    let paymentDate = order.updated;

    return  <div className="profile-orders-article">
        <div className="order-summary">
            <div className="order-summary-details">
                <span>Pedido No: </span>
                <span>{order._id}</span>

                <span>Fecha: </span>
                <span>{new Date(paymentDate).toLocaleDateString()}</span>
            </div>
            <div className="order-summary-details">
                <span>Total: </span>
                <span>{currencyFormatter.format((total - discounts) * 1.16)} MXN</span>
            </div>
            <div className="order-summary-details">
                <span>Enviar a: </span>
                <span>{order.address.name}</span>
            </div>
            <div className="order-summary-details">
                <button className="actionButton" onClick={toggleOpen}>{!open ? "Ver Detalles" : "Ocultar Detalles"}</button>
            </div>
        </div>
        {
            open && <div style={{width:'100%', height:'100%', display:"flex", flexFlow:"column", flex:"1", justifyContent:"center", alignItems:"center"}}>
            <div className="order-summary-details" style={{width:'100%', borderBottom:'1px #00969c solid', paddingLeft:'2rem', paddingTop:'1rem', paddingBottom:'1rem', boxSizing:'border-box'}}>
                <span>Enviar a: </span>
                <span>{order.address.name}</span>
                <span>{`${order.address.street} No. ${order.address.exterior}, ${order.address.interior}`}</span>
                <span>{`${order.address.city}, ${order.address.state}`}</span>
                <span>{`${order.address.colony}`}</span>
                <span>{`${order.address.zipcode}`}</span>
            </div>

            <div className="cart-products-list">
                {
                    order.products.map((product) => {
                        return <CartProduct product={product}/>
                    })
                }
            </div>
            <div style={{backgroundColor:'#00969c', width:'100%', height:'2px'}}></div>
            <div className="cart-totals" style={{width:'100%', paddingLeft:'2rem', paddingRight:'2rem', boxSizing:'border-box', fontWeight:'bold'}}>
                <div className="shopping-product-details" style={{textAlign:'right', alignItems:'flex-end'}}>
                    <span>Sub total productos: {currencyFormatter.format(total) } MXN</span>
                    <span>Descuentos: {currencyFormatter.format(discounts)} MXN</span>
                    <span>Sub total: {currencyFormatter.format(total - discounts) } MXN</span>
                    <span>IVA: {currencyFormatter.format((total-discounts) * 0.16)} MXN</span>
                    <span>Total: {currencyFormatter.format((total - discounts) * 1.16) } MXN</span>
                </div>
            </div>
        </div>
        }
    </div>

}

const ProfileOrders = () => {

    const [orders, setOrders] = useState([]);

    useEffect(()=>{
        const p = async()=>{
            const data = await getOrders();
            data.sort((a,b)=>{
                const dateA = new Date(a.updated);
                const dateB = new Date(b.updated);
                return dateB.getTime() - dateA.getTime();
            })
            setOrders(data);
        }
        p();
    }, []);



    return  <section className="profile-orders">
        <section className="profile-orders-data">
            {
                orders.map((order) => {
                    return <Order order={order} />
                })
            }
        </section>
    </section>

};

export {ProfileOrders};
export default ProfileOrders;