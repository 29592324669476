import React, {useState, useRef, useEffect} from "react";
import {LibrarySection} from '../components/LibrarySection';
import {LibraryData} from '../components/LibraryData';

import img_videos from '../assets/images/library_videos.jpg';
import img_social from '../assets/images/library_social.jpg';
import img_impresos from '../assets/images/library_impreso.jpg';
import img_learning from '../assets/images/library_learning.jpg';

const sections = [
    {
        section: "library-section-videos",
        name: "Videos",
        image: img_videos
    },
    {
        section: "library-section-social",
        name: "Materiales para Redes Sociales",
        image: img_social
    },
    {
        section: "library-section-impreso",
        name: "Materiales Impresos",
        image: img_impresos
    },
    {
        section: "library-section-learning",
        name: "E-learning",
        image: img_learning
    }
];


const Library = () => {
    const [selectedSection, setSelectedSection] = useState('');
    const libraryElement = useRef();
    const setSelection = (data)=>{
        setSelectedSection(data);
    };
    useEffect(()=>{
        console.log(libraryElement.current);
        libraryElement.current.scrollIntoView({behavior: 'smooth'});
    }, [selectedSection])
    console.log(selectedSection);
    return <div className="library-content">
        <div className="library-sections">
            {
                sections.map((section) => 
                    <LibrarySection {...section} selected={selectedSection === section.section} onSelect={setSelection}/>
                )
            }
        </div>
        <div ref={libraryElement} id="library-data" className="library-data">
            {
                selectedSection !== '' && <LibraryData section={selectedSection} />
            }
        </div>
    </div>
};

export {Library};
export default Library;