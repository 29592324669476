import React from "react";
import {
    NavLink
} from "react-router-dom";
import {Cart} from "./Cart";
import vivacy_logo from "../assets/images/vivacy_logo.png";


const Header = () => {
    return <div className="main-header">
        <NavLink to="/"><img src={vivacy_logo} alt="Logo Vivacy"/></NavLink>
        <Cart />
    </div>
};


const UnAuthHeader = () => {
    return <div className="main-header">
        <NavLink to="/"><img src={vivacy_logo} alt="Logo Vivacy"/></NavLink>
    </div>
};

export {UnAuthHeader, Header}
export default UnAuthHeader;


  