import React, {useEffect, useState} from "react";
import {AuthMenu, AuthMobileMenu} from "../components/Menu";
import {Header} from "../components/Header";

import {Burger} from "../components/Burger";

import {getCart} from "../api/products";
import {CartContext} from "../CartContext";

import {useLocation} from 'react-router-dom';
import ReactGA from 'react-ga';

const AuthenticatedLayout = ({children}) => {

    const location = useLocation();
    useEffect(()=>{
        ReactGA.pageview(location.pathname + location.search);
    },[location]);
  
  
    const [open, setOpen] = useState(false);
    const [cart, updateCart] = useState({});

    useEffect(()=>{
        const load = async()=>{
            const userCart = await getCart();
            updateCart(userCart);
        };
        load();
    },[])

    return <CartContext.Provider value={{cart, updateCart}} >
        <div className="main">
            <div className="desktop-header">
                <Header />
                <AuthMenu />
            </div>
            <div className="mobile-header">
                <div className="mobile-burguer">
                    <Burger open={open} setOpen={setOpen} />
                    <Header />
                </div>
            </div>
            <div className="content">
                <AuthMobileMenu open={open} setOpen={setOpen}/>
                <div style={{display:'flex', flex:1}} onMouseDown={()=>{setOpen(false);}}>
                    {children}
                </div>
            </div>
        </div>
    </CartContext.Provider>
};

export {AuthenticatedLayout};
export default AuthenticatedLayout;