import React from "react";

const Modal = ({show, children, cancel, ok}) => {

    const okFn = (evt)=>{
        evt.stopPropagation();
        ok();
    }
    const cancelFn = (evt) => {
        evt.stopPropagation();
        cancel();
    }
    if(!show) return <></>;
    return <div className="modal-overlay" onClick={cancel}>
        <div className="modal">
            <div className="modal-text">
                {children}
            </div>

            <div className="modal-buttons">
                <button className="actionButton" style={{backgroundColor:"transparent", color:'black'}} onClick={cancelFn}>Cancelar</button>
                <button className="actionButton" onClick={okFn}>Continuar</button>
            </div>

        </div>
    </div>
};

export {Modal}
export default Modal;
