
export const loadProducts = () => {
  const token = localStorage.getItem('jwtToken');
  return fetch(process.env.REACT_APP_API_URI + "/products", {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `${token}`
    }
  })
  .then((response)=>{
    console.log(response);
    return response.json()
  })
};

export const getOrders = () => {
  const token = localStorage.getItem('jwtToken');
  return fetch(process.env.REACT_APP_API_URI + "/orders", {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `${token}`
    }
  })
  .then((response)=>{
    console.log(response);
    return response.json()
  })
};


export const addToCart = (productId, productAmount) => {
  const token = localStorage.getItem('jwtToken');
  return fetch(process.env.REACT_APP_API_URI + "/cart", {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `${token}`
    },
    body:JSON.stringify({
      id: productId,
      amount: productAmount
    })
  })
  .then((response)=>{
    console.log(response);
    return response.json()
  })
}

export const updateProduct = (productId, productAmount) => {
  const token = localStorage.getItem('jwtToken');
  return fetch(process.env.REACT_APP_API_URI + "/cart/" + productId, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `${token}`
    },
    body:JSON.stringify({
      id: productId,
      amount: productAmount
    })
  })
  .then((response)=>{
    console.log(response);
    return response.json()
  })
}

export const deleteProduct = (productId, productAmount) => {
  const token = localStorage.getItem('jwtToken');
  return fetch(process.env.REACT_APP_API_URI + "/cart/" + productId, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `${token}`
    },
  })
  .then((response)=>{
    console.log(response);
    return response.json()
  })
}

export const savePaypal = (details, order) => {
  const token = localStorage.getItem('jwtToken');
  return fetch(process.env.REACT_APP_API_URI + "/cart/paypal", {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `${token}`
    },
    body:JSON.stringify({
      details,
      order
    })
  })
  .then((response)=>{
    console.log(response);
    return response.json()
  })
}

export const saveContact = (details, order) => {
  const token = localStorage.getItem('jwtToken');
  return fetch(process.env.REACT_APP_API_URI + "/cart/contact", {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `${token}`
    },
  })
  .then((response)=>{
    console.log(response);
    return response.json()
  })
}


export const getCart = (productId, productAmount) => {
  const token = localStorage.getItem('jwtToken');
  return fetch(process.env.REACT_APP_API_URI + "/cart", {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `${token}`
    },
  })
  .then((response)=>{
    console.log(response);
    return response.json()
  })
}

export const getOrder = (productId, productAmount) => {
  const token = localStorage.getItem('jwtToken');
  return fetch(process.env.REACT_APP_API_URI + "/cart", {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `${token}`
    },
  })
  .then((response)=>{
    console.log(response);
    return response.json()
  })
}

export const getAddress = (productId, productAmount) => {
  const token = localStorage.getItem('jwtToken');
  return fetch(process.env.REACT_APP_API_URI + "/cart/address", {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `${token}`
    },
  })
  .then((response)=>{
    console.log(response);
    return response.json() || {
      name: '',
      street: '',
      exterior: '',
      interior: '',
      state: '',
      city: '',
      colony: '',
      zipcode: ''
    };
  })
}

export const saveAddress = (address) => {
  const token = localStorage.getItem('jwtToken');
  return fetch(process.env.REACT_APP_API_URI + "/cart/address", {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `${token}`
    },
    body: JSON.stringify(address)
  })
  .then((response)=>{
    console.log(response);
    return response.json() || {
      name: '',
      street: '',
      exterior: '',
      interior: '',
      state: '',
      city: '',
      colony: '',
      zipcode: ''
    };
  })
}
