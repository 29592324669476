import jwt_decode from "jwt-decode";

export const loginUser = (userData) => {
  return fetch(process.env.REACT_APP_API_URI + "/login", {
    method: 'POST',
    body: JSON.stringify(userData),
    headers: {
      'Content-Type': 'application/json'
    }
  })
  .then((response)=>{
    console.log(response);
    return response.json()
  })
  .then(res => {
    console.log(res);
    const { token } = res;
    if(token){
      localStorage.setItem("jwtToken", token);
      const decoded = jwt_decode(token);
      return decoded;
    }else{
      return false;
    }
  });
};
  