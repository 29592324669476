import React, {useState, useEffect} from "react";
import {getAddress, getOrder, saveAddress} from '../api/products';

import {useHistory} from 'react-router-dom';

const CartAddress = () => {

    const [order, setOrder] = useState();
    const [address, setAddress] = useState({
        name: '',
        street: '',
        exterior: '',
        interior: '',
        state: '',
        city: '',
        colony: '',
        zipcode: '',
        store: false
    });
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const history = useHistory();
    useEffect(()=>{
        const p = async()=>{
            const savedAddress = await getAddress();
            const cart = await getOrder();
            setOrder(cart);
            setAddress(savedAddress);
            setLoading(false);
        }
        p();
    },[]);

    const validString = (string) => {
        if(!string) return false;
        return !(string.length === 0 || string === "");
    }
    const saveFormAddress = async (event)=>{
        event.preventDefault();
        console.log(address);
        if(!validString(address.name)){
            alert(`Nombre de quien recibe es requerido`);
            return;
        }
        if(!validString(address.street)){
            alert(`Calle es requerido`);
            return;
        }
        if(!validString(address.state)){
            alert(`Estado es requerido`);
            return;
        }
        if(!validString(address.city)){
            alert(`Municipio es requerido`);
            return;
        }
        if(!validString(address.colony)){
            alert(`Colonia es requerido`);
            return;
        }
        if(!validString(address.exterior)){
            alert(`Número exterior es requerido`);
            return;
        }
        if(!validString(address.zipcode)){
            alert(`C.P. es requerido`);
            return;
        }
        setSaving(true);
        try{
            const newAddress = await saveAddress(address);
            if(newAddress){
                setSaving(false);
                history.push('/cart/payment');
            }else{
                alert(`Ocurrió un error guardando la dirección de envío. Por favor intente de nuevo más tarde`);
            }
        }catch(e){
            alert(`El servicio no está disponible temporalmente. Por favor intente de nuevo más tarde.`);
        }
        setSaving(false);
    }

    const setStringAttribute = (attribute, value, name) => {
        setAddress({
            ...address,
            [attribute] : value
        });
    }

    const setNumberAttribute = (attribute, value, name) => {
        const newValue = value;
        if(!Number(newValue) && newValue.length > 0) return;
        setAddress({
            ...address,
            [attribute] : newValue
        });    
    }

    if(loading){
        return <h3>Dirección de envío</h3>
    }else if(order?.products?.length > 0){
        return <div style={{width:'100%', height:'100%', display:"flex", flexFlow:"column", flex:"1", justifyContent:"center", alignItems:"center"}}>
            <form className="profile-info" onSubmit={saveFormAddress}>
                <h3>Dirección de envío</h3>
                <div className="profile-info-data">
                    <div className="profile-info-value">
                        <span>Nombre de quien recibe:</span>
                        <input type="text" value={address.name} onChange={(e)=>{setStringAttribute('name', e.target.value, 'Nombre de quien recibe')}} />
                    </div>
                </div>
                <div className="profile-info-data">
                    <div className="profile-info-value">
                        <span>Calle:</span>
                        <input type="text" value={address.street} onChange={(e)=>{setStringAttribute('street', e.target.value, 'Calle')}}/>
                    </div>
                    <div className="profile-info-value">
                        <span>Número exterior:</span>
                        <input type="text" value={address.exterior} onChange={(e)=>{setNumberAttribute('exterior', e.target.value, 'Número exterior')}}/>
                    </div>
                </div>
                <div className="profile-info-data">
                    <div className="profile-info-value">
                        <span>Interior:</span>
                        <input type="text" value={address.interior} onChange={(e)=>{setStringAttribute('interior', e.target.value, 'Número interior')}}/>
                    </div>
                </div>
                <div className="profile-info-data">
                    <div className="profile-info-value">
                        <span>Estado</span>
                        <input type="text" value={address.state} onChange={(e)=>{setStringAttribute('state', e.target.value, 'Estado')}}/>
                    </div>
                    <div className="profile-info-value">
                        <span>Municipio</span>
                        <input type="text" value={address.city} onChange={(e)=>{setStringAttribute('city', e.target.value, 'Municipio')}}/>
                    </div>            
                </div>
                <div className="profile-info-data">
                    <div className="profile-info-value">
                        <span>Colonia</span>
                        <input type="text" value={address.colony} onChange={(e)=>{setStringAttribute('colony', e.target.value, 'Colonia')}}/>
                    </div>
                    <div className="profile-info-value">
                        <span>C.P.</span>
                        <input type="text" value={address.zipcode} onChange={(e)=>{setNumberAttribute('zipcode', e.target.value, 'C.P.')}}/>
                    </div>            
                </div>
                <div className="profile-info-data">
                    <div className="profile-info-value" style={{flexDirection:'row', alignItems:'center', }}>
                        <input type="checkbox" checked={address.store} onChange={(e)=>{setAddress({...address, "store" : e.target.checked})}}/><span>Guardar esta dirección para futuras compras.</span>
                    </div>
                </div>


                <span>Nota: Verifique sus datos antes de continuar.</span>
                <div className="saveButton">
                    <button 
                        disabled={saving} 
                        className={'actionButton ' + (saving? 'actionButtonDisabled': '')} 
                        style={{textDecoration:'none'}}>
                            Proceder a pago
                    </button>

                </div>
            </form>
        </div>
    }else{
        return <h3>Aún no tienes productos en tu carrito.</h3>;
    }
};

export {CartAddress};
export default CartAddress;