import image_s from '../assets/images/S.png';
import image_m from '../assets/images/M.png';
import image_l from '../assets/images/L.png';
import image_xl from '../assets/images/XL.png';
import image_xxl from '../assets/images/XXL.png';
import image_lips from '../assets/images/Lips.png';

const product_images = {
    'S': image_s,
    'M': image_m,
    'L': image_l,
    'XL': image_xl,
    'XXL': image_xxl,
    'Lips': image_lips
};

export default product_images;