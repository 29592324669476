import React from "react";

const LibrarySection = ({name, image, section, selected, onSelect}) => {
    const sectionClicked = () => {
        console.log("CLICK " + section);
        onSelect(section);
    }
    return <div className={"library" + (selected ? " library-selected" : "")} onClick={sectionClicked}>
        <img src={image} alt={"Biblioteca Vivacy " + name} />
        <p className="library-name">{name}</p>
    </div>
};

export {LibrarySection}
export default LibrarySection;


  