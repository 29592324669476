import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import ReactGA from "react-ga";

import {HomeRedirect} from './routes/HomeRedirect';
import {ProtectedRoute} from './routes/ProtectedRoute';

import {UnauthenticatedLayout} from './layouts/Unauthenticated';
import {AuthenticatedLayout} from './layouts/Authenticated';

import {Login} from './views/Login';
import {Home} from './views/Home';
import {Profile} from './views/Profile';
import {Shopping} from './views/Shopping';
import {Cart} from './views/Cart';
import {Library} from './views/Library';
import {Trace} from './views/Trace';
import {Launch} from './views/Launch';
import jwtDecode from 'jwt-decode';

import "./App.css";

const Logout = (props) => {
  localStorage.clear('jwtToken');
  window.location.href='/';
}

export default function App() {
  let token = localStorage.getItem('jwtToken')

  let Layout = UnauthenticatedLayout;
  if(token){
      let tokenExpiration = jwtDecode(token).exp;
      let dateNow = new Date();
      if(tokenExpiration < dateNow.getTime()/1000){
          Layout = UnauthenticatedLayout;
      }else{
          Layout = AuthenticatedLayout;
      }
  } else {
     Layout = UnauthenticatedLayout;
  }
  return (
      <Router>
          <Layout>
            <Switch>
              <Route exact path="/" component={HomeRedirect} />
              <Route exact path="/login" component={Login} />
              <Route exact path="/logout" component={Logout} />
              <Route path="/library" component={Library} />
              <Route path="/trace" component={Trace} />
              <Route path="/launch" component={Launch} />
              <Route exact path="/home" component={Home} />
              <ProtectedRoute path="/profile" component={Profile} />
              <ProtectedRoute path="/shopping" component={Shopping} />
              <ProtectedRoute path="/cart" component={Cart} />
            </Switch>
          </Layout>
      </Router>
  );
}
